<template lang="pug">
include ../../../configs/template.pug
div.row
  div(v-if="checkAccess('qualification', 'preVerification', sailorDocument)").col-12
    +select('body.status_document', 'listStatuses', 'status', 'nameLang')
  div(v-if="checkAccess('qualification', 'preVerification', sailorDocument) && body.status_document === STATUSES_DOCUMENT_CONSTANTS.SAILOR.QUALIFICATION.REJECTED").col-12
    //- VerificationRefusal(:type="sailorDocument.type_document === 16 ? 'diplomaProof' : 'qualification'" ref="verificationRefusal")
    Reject(:sailorDocument="sailorDocument")
  div.col-12.mt-3
    v-btn(
      v-if="body.status_document !== STATUSES_DOCUMENT_CONSTANTS.SAILOR.QUALIFICATION.REJECTED"
      @click="checkAccess('qualification', 'preVerification', sailorDocument) ? saveNewStatus() : checkSave()"
      :loading="isLoading"
      color="success") {{ checkAccess('qualification', 'preVerification', sailorDocument) ? $t('save') : $t('setVerify') }}
</template>

<script>
import { checkAccess } from '@/mixins/permissions'
import { mapState, mapGetters, mapActions } from 'vuex'
// import VerificationRefusal from '@/components/atoms/VerificationRefusal/VerificationRefusal.vue'
import { SUCCESS_CODE, STATUSES_DOCUMENT_CONSTANTS } from '@/configs/constants'

export default {
  props: {
    sailorDocument: { type: Object, default: () => ({}) }
  },
  components: {
    Reject: () => import('./Reject.vue')
  },
  data () {
    return {
      STATUSES_DOCUMENT_CONSTANTS,
      body: {
        status_document: this.sailorDocument.status_document
      },
      isLoading: false,
      checkAccess
    }
  },
  computed: {
    ...mapGetters(['statusChoose', 'statusById']),
    ...mapState({
      nameLang: state => (state.main.lang === 'en') ? 'name_eng' : 'name_ukr'
    }),
    listStatuses () {
      let statuses = this.statusChoose('QualificationDoc')
      if (checkAccess('admin')) statuses = statuses.concat(this.statusChoose('BackOffice'))
      if (this.sailorDocument.status_document === 19) {
        statuses = statuses.filter(value => value.id !== 21)
        // if (checkAccess('backOffice')) statuses.push(this.statusById(124))
      }
      // // Allow changing status "service" only to "valid"
      // if (this.sailorDocument.status_document === 124) statuses = [this.statusById(19), this.statusById(124)]
      return statuses
    }
  },
  methods: {
    ...mapActions(['updateQualificationDocuments', 'updateQualificationDiplomaProof']),

    checkSave () {
      this.$swal({
        title: this.$t('warning'),
        text: this.$t('continueVerification'),
        icon: 'info',
        buttons: [this.$t('cancel'), this.$t('setVerify')],
        dangerMode: true
      }).then((confirmation) => {
        if (confirmation) this.saveNewStatus()
      })
    },

    async saveNewStatus () {
      const body = {
        status_document: checkAccess('qualification', 'preVerification', this.sailorDocument)
          ? this.body.status_document
          : STATUSES_DOCUMENT_CONSTANTS.SAILOR.QUALIFICATION.VERIFICATION
      }

      // I declare a variable here, because the component no longer exists inside the response and an undefined error appears
      // let setVerificationRefusal = null
      // if (checkAccess('qualification', 'preVerification', this.sailorDocument) && this.status === 37) setVerificationRefusal = this.$refs.verificationRefusal.setVerificationRefusal

      this.isLoading = true
      const response = await this[this.sailorDocument.type_document === 16 ? 'updateQualificationDiplomaProof' : 'updateQualificationDocuments']({ ...this.$route.params, body })
      if (SUCCESS_CODE.includes(response?.code)) {
        this.$notification.success('editedQualificationDoc')
        if (this.sailorDocument.status_document === STATUSES_DOCUMENT_CONSTANTS.SAILOR.QUALIFICATION.VERIFICATION &&
          response.data.status_document !== STATUSES_DOCUMENT_CONSTANTS.SAILOR.QUALIFICATION.VERIFICATION) {
          this.$store.commit('decrementUserNotification', 'documents_on_verification')
        }
        // if (setVerificationRefusal) setVerificationRefusal()
      }
      this.isLoading = false
    }
  }
}
</script>
